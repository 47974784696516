/* src/components/Themes.css */

/*
.themes-container {
    max-width: 90%;
    flex-direction: column;
}*/

.sets-list-container, .themes-list-container {
    max-width: 90%;
}

.sets-container, .themes-list-container, .sets-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 20px;
    margin: 1rem auto;
}

.theme-button {
    width: 170px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    color: black;
    background-color: white;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

/*
.theme-button span {
    white-space: nowrap;
}
*/

.theme-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.theme-button.highlighted {
    /*box-shadow: 0 4px 8px rgba(255, 0, 0, 0.2);*/
    border-bottom: 2px solid #da291c;
    font-weight: bold;
}

.theme-button svg {
    color: #da291c;
}

.back-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #da291c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.back-button:hover {
    background-color: #c82333;
}

/* src/components/Sets.css */

.set-card {
    width: 150px;
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;
}

.set-card.horizontal {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .set-card {
        width: 130px;
        padding: 0.5rem;
    }

    .themes-list-container {
        max-width: unset;
    }
}

.sets-list-container.set-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sets-list-container.set-card.selected {
    background-color: lightgray;
    border-color: #009FFF;
    box-shadow: 0 0 0 2px #009FFF;
}

.set-card.incomplete {
    border: 1px solid #ffbf00;
    /*background: #ffd70040;*/
    box-shadow: inset 0px 0px 8px 4px #ffbf006e;
}

.set-card.selected.incomplete {
    background-color: #ffd90077;
}


.set-image {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.set-name {
    font-size: 14px;
    font-weight: bold;
}

.set-year {
    /*margin-top: 10px;*/
    font-size: 14px;
    font-weight: 500;
}

.register-button, .login-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.register-button:hover, .login-button:hover {
    color: white;
    background-color: #0056b3;
}

a {
    text-decoration: none;
}

.add-to-collection-button:hover {
    background-color: #0056b3;
}

.theme-header, .user-collections-header {
    text-align: center;
    margin: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.search-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.search-bar {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-button {
    padding: 0px 15px;
    margin: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-results {
    margin-top: 20px;
}

.search-result-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
}

.search-result-item img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

/* NEW SubThemes Section */
/* Theme Navigation Section */
.theme-navigation-section {
    max-width: 90%;
    margin: 2rem auto;
}

.theme-header-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.theme-subtheme-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.navigation-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #da291c;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
}

.navigation-button:hover {
    background-color: #c82333;
}

.button-icon {
    font-size: 14px;
}

/* Subthemes Section */
.subthemes-section {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subtheme-section-header {
    font-size: 20px;
    color: #333;
    margin: 0;
}

.subthemes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}

.subtheme-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
}

.subtheme-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subtheme-name {
    font-size: 14px;
    color: #333;
}

.chevron-icon {
    color: #666;
    font-size: 12px;
}

/* Sets Section */
.sets-section {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sets-header {
    padding: 15px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.selected-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.wishlist-action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f0c51c;
    color: #333;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.2s;
}

.wishlist-action-button:hover {
    background-color: #e0b710;
}

.collection-action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.2s;
}

.collection-action-button:hover {
    background-color: #0056b3;
}

.selected-count {
    font-size: 14px;
    color: #666;
    padding: 0.5rem 1rem;
    background-color: #f5f5f5;
    border-radius: 20px;
}

.sets-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    margin: 1rem auto;
    padding: 1rem;
}

.loading-indicator {
    margin-top: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .theme-header-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .sets-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .selected-actions {
        flex-direction: column;
        width: 100%;
    }

    .collection-action-button,
    .wishlist-action-button {
        width: 100%;
        justify-content: center;
    }

    .sets-container, .sets-list-container, .themes-list-container {
        gap: 0.5rem;
        margin: 0.5rem auto;
    }
}