footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    padding-top: 2rem;
    border-top: 1px solid #dbdbdb;
  }
  
  .footer-container {
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  
  .footer-item {
    display: flex;
    align-content: center;
    flex-direction: column;
    padding: 0 10px;
  }

  .about-group a {
    margin-bottom: 10px;
  }

  .about-group span {
    white-space: nowrap;
    display: flex;
  }

  .footer-item p {
    margin: 0px 5px;
  }
  
  .footer-item h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-top: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--first-color);
  }
  
  .footer-copyright {
    width: 100%;
    margin-top: 32px;
    text-align: center;
    background-color: #29292918;
  }
  
  .footer-container .footer-item ul {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 0;
    margin-top: 0;
    flex-direction: row;
  }
  
  .footer-nav-group ul {
    flex-direction: column;
  }
  
  .follow-group ul {
    gap: 25px !important;
  }
  
  .footer-container li {
    list-style-type: none;
  }
  
  .footer-container li a {
    text-decoration: none;
    color: inherit;
  }
  
  .footer-nav-group li {
    font-size: 1.1em;
  }
  
@media (max-width: 768px) {
    footer {
      padding-top: 1rem;
    }

    .footer-container {
        flex-direction: column;
    }

    .footer-item {
        padding: 0 20px;
    }

    .footer-copyright {
      margin-top: 10px;
    }
}