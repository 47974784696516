/* Collection.css */

/* Profile Section Styles */
.collection-profile-section {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    max-width: 90%;
    margin: 2rem auto;
}



.collection-profile-section .collection-profile-header {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    padding: 2rem;
}

.profile-social {
    margin-left: auto;
    padding-left: 24px;
    border-left: 1px solid #eee;
    min-width: 200px;
}

.social-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.social-link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 14px;
}

.social-link:hover {
    color: #fff;
}

.social-link svg {
    width: 16px;
    height: 16px;
}

.profile-image-container {
    flex-shrink: 0;
}



.collection-profile-section .profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #FFD700;
}



.profile-info {
    flex-grow: 1;
}



.profile-name {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 1rem 0;
    color: #333;
}



.profile-location,
.profile-favorite-theme {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.profile-bio {
    margin: 1rem 0 0 0;
    font-size: 15px;
    line-height: 1.5;
    color: #444;
    white-space: pre-line;
}



/* Collection Header */
.collection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    margin: 2rem auto;
    flex-wrap: wrap;
    gap: 1rem;
}



.collection-header h2 {
    font-size: 22px;
    margin: 0;
    color: #333;
}



/* Stats Section */
.stats-container {
    margin: 2rem auto;
    max-width: 90%;
}



.collection-stats {
    display: flex;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: space-around;
    text-align: center;
    margin: auto;
}



.stats-section {
    flex: 1;
    padding: 0 15px;
    position: relative;
}



.stats-section:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 10%;
    height: 80%;
    width: 1px;
    background-color: #eee;
}



.stats-header {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}



.stats-value {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Theme Section */
.collection-themes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 90%;
    margin: 2rem auto;
}

.collection-theme-section {
    flex: 0 0 100%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.collection-theme-section.half {
    flex: 0 1 calc(50% - 1rem - 2px);
    align-self: flex-start; /* This prevents height matching */
}

.collection-theme-section.half:nth-child(odd):last-child {
    margin-right: auto;
}

.theme-title {
    padding: 15px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0; /* Prevents the title from shrinking */
}

.theme-icon {
    font-size: 16px;
    color: #666;
}

.collection-theme-section.collapsed .sets-container {
    display: none;
}

.theme-icon {
    font-size: 16px;
    color: #666;
    transition: transform 0.3s ease;
}

.collection-theme-section.collapsed .theme-icon {
    transform: rotate(-90deg);
}

/* Set Card */

.set-card {
    width: 220px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
    transition: width 0.3s ease;
}

.set-card.show-prices {
    width: 240px;
    text-align: left;
}

.set-card-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.set-card.selected {
    background-color: #e5f0fd;
    border-color: #2b91ff;
    box-shadow: 0 0 0 2px #2b91ff;
}

.sets-container .set-card.incomplete.selected {
    background-color: #ffd90054;
    border-color: #ffbf00;
    box-shadow: 0 0 0 2px #ffbf00;
}

.set-card-collection-container {
    display: flex;
    flex-direction: row;
}

.set-image-container {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.set-image-container.collection {
    width: 50%;
    margin-right: 12px;
    margin-bottom: unset;
}

.set-info-container {
    display: flex;
    flex-direction: row;
}

.set-info {
    width: 100%;
    text-align: center;
}

.set-image .collection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.set-name {
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 1.3;
}

.set-details {
    font-size: 14px;
    color: #666;
    display: flex;
    justify-content: space-between;
}

.set-details {
    font-size: 14px;
    color: #666;
    display: flex;
    justify-content: space-between;
}

.set-prices-container {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #eee;
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.3s ease;
}

.show-prices .set-prices-container {
    opacity: 1;
    transform: translateX(0);
}

.set-prices {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #eee;
    font-size: 13px;
    color: #666;
}

.price-row {
    margin-bottom: 8px;
    font-size: 13px;
}

.price-label {
    display: block;
    color: #666;
    margin-bottom: 2px;
}

.price-value {
    font-weight: 500;
}

.price-value.retail {
    color: #666;
}

.price-value.sealed {
    color: #2e7d32;
}

.price-value.used {
    color: #1976d2;
}

/* Set Actions */
.set-actions {
    margin-top: 8px;
    border-top: 1px solid #979797;
    padding-top: 8px;
}

.price-toggle-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    font-weight: 500;
}

.set-actions .quantity-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 8px;
}



.quantity-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}



.quantity-display {
    font-size: 16px;
    min-width: 30px;
    text-align: center;
    background: white;
    border-radius: 5px;
    padding: 2px 4px;
}



.set-action-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.show-prices .set-action-buttons {
    flex-direction: row;
}

.status-button,
.remove-button {
    flex: 1;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.status-button.complete {
    background-color: #FFD700;
    color: black;
}

.status-button.incomplete {
    background-color: #007bff;
    color: #fff;
}

.remove-button {
    background-color: #c62828;
    color: #fff;
}

.quantity-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #FFD700;
    color: #333;
    border-radius: 50%;
    padding: 6px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid white;
}

.collection-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

/* Share Button */
.share-button, .wishlist-link-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 20px;
    border: none;
    background-color: #FFD700;
    color: #333;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
}

.wishlist-link-button a {
    color: #333;
}

.share-button:hover, .wishlist-link-button:hover {
    background-color: #FFC800;
    color: #333;
}

.share-icon {
    font-size: 16px;
}

/* Empty State */
.empty-collection {
    text-align: center;
    padding: 40px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Loading State */
.loading {
    text-align: center;
    padding: 40px;
    color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
    .collection-theme-section.half {
        flex: 0 0 100%;
    }

    .collection-profile-header {
        flex-direction: column;
        align-items: center !important;
        text-align: center;
    }

    .collection-header h2 {
        margin: auto;
    }

    .profile-social {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid #eee;
        padding-top: 16px;
        margin-top: 16px;
        width: 100%;
    }

    .social-links {
        align-items: center;
    }

    .collection-actions {
        flex-direction: column;
        width: 100%;
    }

    .profile-info {
        width: 100%;
    }

    .profile-location,
    .profile-favorite-theme {
        justify-content: center;
    }

    .collection-stats {
        flex-direction: column;
        gap: 20px;
    }

    .stats-section:not(:last-child)::after {
        display: none;
    }
}

@media (max-width: 480px) {
    .set-action-buttons {
        flex-direction: column;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
}

.modal-header {
    margin-bottom: 15px;
}

.modal-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

.modal-body {
    margin-bottom: 20px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.modal-button.cancel {
    background-color: #e0e0e0;
}

.modal-button.confirm {
    background-color: #dc3545;
    color: white;
}

.modal-button:hover {
    opacity: 0.9;
}