/* src/components/About.css */
.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.about-header {
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.about-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-content .profile-section {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;
}

.profile-section .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #ccc;
    object-fit: cover;
}

.profile-text {
    flex: 1;
}

.profile-text h2 {
    margin: 0 0 15px 0;
    color: #333;
}

.profile-text p {
    color: #555;
    line-height: 1.6;
}

.social-section {
    margin-bottom: 40px;
}

.social-section h3 {
    color: #333;
    margin-bottom: 20px;
}

.about-social-links {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
}

.social-link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.social-link:hover {
    background-color: #0056b3;
}

.support-section {
    margin-bottom: 40px;
}

.support-section h3 {
    color: #333;
    margin-bottom: 20px;
}

.support-section p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

.crypto-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.crypto-item {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
}

.crypto-item h4 {
    margin: 0 0 10px 0;
    color: #333;
}

.wallet-address {
    display: flex;
    align-items: center;
    gap: 10px;
    background: white;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.wallet-address code {
    flex: 1;
    font-family: monospace;
    color: #666;
    word-break: break-all;
}

.copy-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 5px;
    transition: color 0.2s;
}

.copy-button:hover {
    color: #0056b3;
}

.disclaimer {
    text-align: center;
    color: #999;
    font-size: 0.9em;
    margin-top: 40px;
}

@media (max-width: 768px) {
    .profile-section {
        flex-direction: column;
        text-align: center;
    }

    .social-links {
        flex-direction: column;
    }

    .social-link {
        justify-content: center;
    }
}