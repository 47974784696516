/* src/components/Styles.css */
body {
    background: #f5f5f5;
}

a {
    color: #ff6000;
}

a:hover {
    color: #a63f00;
}

.loading, .error-message  {
    text-align: center;
}

.centered-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
  }

.collection-nouser-links-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
