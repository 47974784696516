/* src/components/Profile.css */
.profile-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.profile-container .profile-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.profile-page-header h1 {
    margin: 0;
    color: #333;
}

.edit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.edit-button:hover {
    background-color: #0056b3;
}

.profile-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
}

.profile-picture-section {
    text-align: center;
    margin-bottom: 30px;
}

.profile-picture-container {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #007bff;
}

.profile-picture-upload {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: #007bff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.profile-picture-upload:hover {
    background: #0056b3;
}

.camera-icon {
    color: white;
    font-size: 1.2em;
}

.upload-button {
    color: white;
    cursor: pointer;
}

.profile-details {
    max-width: 600px;
    margin: 0 auto;
}

.profile-field {
    margin-bottom: 20px;
}

.profile-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.profile-field input,
.profile-field textarea,
.profile-field select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.profile-field textarea {
    min-height: 100px;
    resize: vertical;
}

.field-value {
    padding: 8px;
    background: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 20px;
}

.field-value.bio {
    white-space: pre-wrap;
    min-height: 60px;
}

.save-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 20px;
    transition: background-color 0.2s;
}

.save-button:hover {
    background-color: #218838;
}

.social-media-section {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.section-title {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
}

.input-with-prefix {
    display: flex;
    align-items: center;
}

.input-prefix {
    background: #f8f9fa;
    padding: 8px;
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 4px 0 0 4px;
    color: #666;
}

.input-with-prefix input {
    border-radius: 0 4px 4px 0;
}

.character-count {
    color: gray;
    font-size: 0.8rem;
}

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox-container input[type="checkbox"] {
    width: auto;
}

.error-message,
.success-message {
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    text-align: center;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.login-prompt {
    text-align: center;
    padding: 40px;
    font-size: 18px;
    color: #666;
}

@media (max-width: 768px) {
    .profile-container {
        padding: 10px;
    }
    
    .profile-content {
        padding: 15px;
    }
}