.home-container {
    text-align: center;
    margin-top: 4rem;
    font-family: Arial, sans-serif;
}

.home-header {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 2rem;
}

.home-subtext {
    font-size: 1.2em;
    margin-bottom: 2rem;
    color: #555;
    padding: 0px 70px;
}

.start-collection-button, .explore-themes-btn {
    display: block;
    margin: 1rem auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.explore-themes-btn a {
    color: white;
}

.explore-themes-btn:hover, .explore-themes-btn:hover a {
    color: white;
    background: #0056b3;
}

.start-collection-button {
    margin-bottom: 40px;
}

.start-collection-button:hover {
    background-color: #0056b3;
}

.home-footer {
    font-size: 0.9em;
    color: #999;
    margin: 2rem auto;
}

.popular-themes-container {
    background: #e1e1e1;
    padding: 5px;
}

.theme-header, .user-collections-header {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.theme-details {
    color: #555;
}

.themes-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: 10px auto;
}

.theme-card {
    cursor: pointer;
    border: 1px solid #ddd;
    background: white;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    width: 150px;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .theme-card {
        width: 140px;
    }

}

.theme-card:hover {
    transform: scale(1.05);
}

.theme-image {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    object-fit: contain;
}

.theme-name {
    padding: 12px;
    font-size: 1.1em;
    font-weight: 500;
}

.theme-count {
    font-size: 0.85em;
    color: #666;
    margin-top: 4px;
    font-weight: normal;
}

.user-collections-container {
    padding: 5px;
    background-color: #f9f9f9;
}

.user-collections-header {
    text-align: center;
    color: #333;
}

.user-collections-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1rem auto;
}

.user-card {
    width: 150px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: white;
}

.user-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.user-image-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .user-image-container .user-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-name {
    font-size: 1em;
    color: #333;
}

.user-link {
    text-decoration: none;
    color: inherit;
}

.show-more-button {
    display: block;
    margin: 2rem auto;
    margin-top: 1rem;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: #555;
    transition: background-color 0.2s, transform 0.2s;
}

.show-more-button:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
}

.sets-list-container {
    transition: max-height 0.3s ease-in-out;
}
